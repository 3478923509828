const handleNewsletterEmailChange: () => void = () => {
	try {
		const emailInput: any = event?.currentTarget || event?.target;
		const gformEmailInput: any = document.getElementById("input_4_4");
		const emailValue: string = emailInput.value;

		emailInput.classList.remove("error");
		gformEmailInput.setAttribute("value", emailValue);

		console.log("emailValue: " + emailValue, gformEmailInput);
	} catch (error) {
		console.log("Error: " + error);
	}
};

const handleNewsletterSubmit: () => void = () => {
	try {
		const submitButton: any = event?.currentTarget || event?.target;
		const form: any = submitButton.closest(".NewsletterPopUp");
		const gform: any = document.getElementById("gform_4");
		const emailInputContainer: any = document.getElementById("newsletterEmail");
		const emailInput: any = emailInputContainer.getElementsByTagName("input")[0];
		const emailValue: string = emailInput.value;
		const gformEmailInput: any = document.getElementById("input_4_4");

		if (!getIsValidEmail(emailValue)) {
			emailInput.classList.add("error");
			return;
		}

		gformEmailInput.setAttribute("value", emailValue);
		form.classList.add("loading");
		gform.submit();
	} catch (error) {
		console.log("Error: " + error);
	}
};

const getIsValidEmail: (value: string) => boolean = (value) => {
	const allowedValues: RegExp = new RegExp(
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	);
	const isValidEmail: boolean = allowedValues.test(value) && value ? true : false;

	return isValidEmail;
};

const gform_confirmation_loaded_NewsletterForm: () => void = () => {
	try {
		const newsletterForm: any = document.getElementsByClassName("NewsletterPopUp")[0];
		let newsletterSession: any = sessionStorage.getItem("newsletterPopup");

		newsletterForm.classList.remove("loading");
		newsletterForm.classList.add("success");

		if (newsletterSession) {
			newsletterSession = JSON.parse(newsletterSession);
			newsletterSession.formCompleted = true;
			sessionStorage.setItem("newsletterPopup", JSON.stringify(newsletterSession));
		}
	} catch (error) {
		console.log("Error: " + error);
	}
};

const closeNewsletterPopUp: () => void = () => {
	try {
		const newsletterForm: any = document.getElementsByClassName("NewsletterPopUp")[0];

		newsletterForm.classList.remove("active");
	} catch (error) {
		console.log("Error: " + error);
	}
};

const resetNewsletterPopUp: () => void = () => {
	try {
		const newsletterForm: any = document.getElementsByClassName("NewsletterPopUp")[0];

		newsletterForm.classList.remove("success");
		newsletterForm.classList.remove("loading");
		newsletterForm.classList.add("active");
	} catch (error) {
		console.log("Error: " + error);
	}
};

const handleNewsletterShow: () => void = () => {
	try {
		const newsletterForm: any = document.getElementsByClassName("NewsletterPopUp")[0];
		let newsletterSession: any = sessionStorage.getItem("newsletterPopup");
		const sessionModel: any = { formCompleted: false, formUpdated: Date.now() };

		if (newsletterSession) {
			newsletterSession = JSON.parse(newsletterSession);
			const timeDiff: number = Date.now() - newsletterSession.formUpdated;
			const diffMinutes: number = Math.ceil(timeDiff / 1000 / 60);
			const showPopUp: boolean = diffMinutes >= 30 && !newsletterSession.formCompleted ? true : false;

			if (showPopUp) newsletterForm.classList.add("active");

			newsletterSession.formUpdated = Date.now();
			sessionStorage.setItem("newsletterPopup", JSON.stringify(newsletterSession));
		} else {
			sessionStorage.setItem("newsletterPopup", JSON.stringify(sessionModel));

			newsletterForm.classList.add("active");
		}
	} catch (error) {
		console.log("Error: " + error);
	}
};
